import styles from './Loader.module.scss'

function Loader() {
  return (
    <div className={styles.loader}>
      <div className={styles.ldsRing}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default Loader
