import Modal from '@/components/Modals/Modal/Modal'

import styles from './PartnerInfo.module.scss'

function PartnerInfo({ isOpen, onClose, partner }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.modalInner}>
        <p>{partner?.adInfo?.company}</p>
        <p>ИНН: {partner?.adInfo?.inn}</p>
        <p>ERID: {partner?.adInfo?.token}</p>
      </div>
    </Modal>
  )
}

export default PartnerInfo
