import { createSlice } from '@reduxjs/toolkit'

import { NameSpace } from '@/store/nameSpace'

export const appSlice = createSlice({
  name: NameSpace.APP,
  initialState: {
    formats: [],
    cities: [],
    selectedCity: {},
    cityFromFormats: {},
    abid: '',
  },

  reducers: {
    setStoreFormats: (state, action) => {
      state.formats = action.payload
    },
    setStoreCities: (state, action) => {
      state.cities = action.payload
    },
    setStoreSelectedCity: (state, action) => {
      state.selectedCity = action.payload
    },
    setStoreCityFromFormats: (state, action) => {
      state.cityFromFormats = action.payload
    },
    setStoreAbid: (state, action) => {
      state.abid = action.payload
    },
  },
})

export const {
  setStoreFormats,
  setStoreCities,
  setStoreSelectedCity,
  setStoreCityFromFormats,
  setStoreAbid,
} = appSlice.actions

export const getStoreFormats = (state) => state[NameSpace.APP].formats
export const getStoreCities = (state) => state[NameSpace.APP].cities
export const getStoreSelectedCity = (state) => state[NameSpace.APP].selectedCity
export const getStoreCityFromFormats = (state) => state[NameSpace.APP].cityFromFormats
export const getStoreAbid = (state) => state[NameSpace.APP].abid

export default appSlice.reducer
