import 'lazysizes'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'

import api from '@/api'
import Main from '@/pages/Main/Main'
// import ResultsTable from '@/pages/ResultsTable'
import {
  getStoreCities, setStoreCityFromFormats, setStoreAbid,
  setStoreCities,
  setStoreFormats,
  setStoreSelectedCity,
} from '@/store/slices/app'
// import { isOnlineCity } from '@/utils/common'

import './App.css'

function App() {
  const location = useLocation()
  const dispatch = useDispatch()
  const cities = useSelector(getStoreCities)

  useEffect(() => {
    getCities()
  }, [])

  const getCities = () => {
    fetch('cities.json')
      .then((r) => r.json())
      .then((r) => {
        dispatch(setStoreCities(r.values))
        setCity(r.values)
      })
      .catch((err) => console.error(err))
  }

  const getFormats = (publicId) => {
    // api.get(`/api/event_format/event/${publicId}?ab=two`).then((r) => {
    api.get(`/api/event_format/event/${publicId}`).then((r) => {  
      dispatch(setStoreFormats(r.data.values))
      dispatch(setStoreCityFromFormats(r.data.city))

      if (r.data?.city?.abid) {
        dispatch(setStoreAbid(r.data.city.abid))
      }
    })
  }

  const getLocalCity = () => {
    const localCity = localStorage.getItem('city')

    if (localCity) {
      try {
        const parseCity = JSON.parse(localCity)

        if (typeof parseCity === 'object') {
          return parseCity
        }
      } catch {
        localStorage.removeItem('city')
      }
    }

    return null
  }

  const setCity = (cities) => {
    const defaultCity = 'zabeg2025_524901'
    const defaultCityOnline = 'zabeg2025_online_999999'
    const moscowId = 'zabeg2025_524901'
    const localCity = getLocalCity(cities)

    if (location.pathname === '/moscow' || location.pathname === '/%D0%BC%D0%BE%D1%81%D0%BA%D0%B2%D0%B0') {
      const findCity = cities.find((item) => item.public_id === moscowId)

      if (findCity) {
        dispatch(setStoreSelectedCity(findCity))
        getFormats(findCity.public_id)
      }
    } else {
      if (localCity) {
        const findCity = cities.find((item) => item.public_id === localCity.public_id)

        if (findCity) {
          dispatch(setStoreSelectedCity(findCity))
          getFormats(findCity.public_id)

          // if (location.pathname !== '/online' && isOnlineCity(findCity)) {
          //   window.location.replace('/online')
          // } else if (location.pathname === '/online' && !isOnlineCity(findCity)) {
          //   window.location.replace('/')
          // }
        }
      } else {
        api.get('/api/location/detailed').then((r) => {
          const findCity = cities.find((item) => item.city.id === r.data.info.city.id)

          if (
            findCity 
            // &&
            // ((location.pathname !== '/online' && !isOnlineCity(findCity)) ||
            //   (location.pathname === '/online' && isOnlineCity(findCity)))
          ) {
            getFormatsAndSetSelectedCity(findCity)
          } else {
            let findCity

            if (location.pathname === '/online') {
              findCity = cities.find((item) => item.public_id === defaultCityOnline)
            } else {
              findCity = cities.find((item) => item.public_id === defaultCity)
            }

            if (findCity) {
              getFormatsAndSetSelectedCity(findCity)
            }
          }
        })
      }
    }
  }

  const getFormatsAndSetSelectedCity = (city) => {
    dispatch(setStoreSelectedCity(city))
    getFormats(city.public_id)
    localStorage.setItem('city', JSON.stringify(city))
  }

  const handleChangeCity = (cityPublicId) => {
    const findCity = cities.find((item) => item.public_id === cityPublicId)

    if (findCity) {
      dispatch(setStoreSelectedCity(findCity))
      getFormats(cityPublicId)
      localStorage.setItem('city', JSON.stringify(findCity))

      // if (location.pathname !== '/online' && isOnlineCity(findCity)) {
      //   window.location.replace('/online')
      // } else if (location.pathname === '/online' && !isOnlineCity(findCity)) {
      //   window.location.replace('/')
      // }

      // if (location.pathname === '/online' && isOnlineCity(findCity)) {
      //   window.location.replace('/')
      // }
    }
  }

  return (
    <Routes>
      <Route
        path="/"
        element={<Main onChangeCity={handleChangeCity} />}
      />
      {/* <Route path="/результаты" element={<ResultsTable onChangeCity={handleChangeCity} />} />
      <Route path="/results" element={<ResultsTable onChangeCity={handleChangeCity} />} /> */}
    </Routes>
  )
}

export default App
