import { useEffect } from 'react'

import { ReactComponent as IconPlus } from '@/assets/images/icons/icon-plus.svg'

import styles from './Modal.module.scss'

function Modal({ isOpen, onClose, children, closeBtnStyles }) {
  useEffect(() => {
    const body = document.querySelector('body')

    if (isOpen) {
      const scrollBarWidth = window.innerWidth - body.offsetWidth
      body.style.overflow = 'hidden'
      body.style.paddingRight = `${scrollBarWidth}px`
    } else {
      body.style.overflow = ''
      body.style.paddingRight = ''
    }

    const handleKeyDown = (evt) => {
      if (evt.key === 'Escape') {
        onClose()
      }
    }

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      body.style.overflow = ''
      body.style.paddingRight = ''
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, onClose])

  if (!isOpen) return null

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <button className={closeBtnStyles ? closeBtnStyles : styles.closeBtn} onClick={onClose}>
          <IconPlus className={styles.closeBtnIcon} />
          <span className="visually-hidden">Закрыть окно</span>
        </button>

        {children}
      </div>
    </div>
  )
}

export default Modal
